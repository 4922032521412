export const Prices = [
  {
    _id: 0,
    name: "GH¢0 to 499",
    array: [0, 499],
  },
  {
    _id: 1,
    name: "GH¢500 to 4999",
    array: [500, 4999],
  },
  {
    _id: 2,
    name: "GH¢5,000 to 99,999",
    array: [5000, 99999],
  },
  {
    _id: 3,
    name: "GH¢100,000 to 499,999",
    array: [100000, 499999],
  },
  {
    _id: 4,
    name: "GH¢500,000 to 999,999",
    array: [500000, 999999],
  },
  {
    _id: 4,
    name: "GH¢1,000,000 or more",
    array: [1000000, 100000000],
  },
];
