import React from "react";
import Layout from "./../components/Layout/Layout";
import logo2 from "../assets/Images/logo2.png";
import { motion } from "framer-motion";

const Policy = () => {
  const imageVariants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.0,
        delay: 0.2,
      },
    },
  };
  return (
    <Layout title={"Privacy Policy"}>
      <div className="row  ">
        <div className="col-md-6 ">
          <motion.img
            src={logo2}
            alt="contactus"
            style={{ width: "80%" }}
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          />
        </div>
        <div className="col-md-4">
          <p style={{ marginTop: "20px" }}>
            At SuperEstates, we take the privacy and security of our users'
            personal information very seriously. As a subsidiary of Super Farms
            Ghana Limited, we understand the importance of trust and
            transparency in our relationships with our clients. This Privacy
            Policy outlines how we collect, use, and protect personal
            information that we receive from our users. We are committed to
            ensuring that all personal information provided to us is kept
            confidential and used only in accordance with this Privacy Policy.
            By using our website and services, you consent to the terms of this
            Privacy Policy.
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>
            Name and address, telephone number, email address
          </h6>
          <p>
            Electronically identifiable information such as IP addresses and
            cookies of visitors of SuperEstates’s websites. While using our
            Websites, we may ask you to provide us with certain personal data
            that can be used to contact or identify you. Personal data may
            include, but is not limited to, your name. More specifically,
            depending on the following purposes, we also collect the following
            personal data: If you contact us by e-mail for a general question,
            we will save your contact details to give you further information
            and to provide you the most adequate answer to your question. If you
            contact us for a Service or a product, we will save the following
            personal data you give to us: name, family name, phone number, email
            address, address and company.
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>
            Automatically Collected Information
          </h6>
          <p>
            When you use our website, we automatically collect certain computer
            information by the interaction of your mobile phone or web browser
            with our website. Such information is typically considered
            non-personal information. We also collect the following:
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>Cookies</h6>
          <p>
            Our website uses "Cookies" to identify the areas of our website that
            you have visited. A cookie is a small piece of data stored on your
            computer or mobile device by your web browser. We use cookies to
            personalize the content that you see on our website. Most web
            browsers can be set to disable the use of cookies. However, if you
            disable cookies, you may not be able to access functionality on our
            website correctly or at all. We never place Personally Identifiable
            Information in cookies.
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>
            Third Party Tracking Tools
          </h6>
          <p>
            We also use third party tracking tools to improve the performance
            and features of our website. These third-party tracking tools are
            designed to collect only non-personal information about your use of
            our website. However, you understand that such tools are created and
            managed by parties outside our control. As such, we are not
            responsible for what information is actually captured by such third
            parties or how such third parties use and protect that information.
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>
            Use of Personal Data
          </h6>
          <p>
            {" "}
            SuperEstates uses personally identifiable data such as names,
            addresses, etc of customers (including information related to
            transactions between customers and the company or its Franchisor or
            other franchised dealers, and hereafter referred to as "personal
            data") for varied reasons, including the following of which one or
            more may apply simultaneously: build customer profiles and
            relationships to ensure client satisfaction; to provide products and
            services, to process requests or as otherwise may be necessary to
            perform the contract between you and SuperEstates; create awareness
            of products and drive sales; to inform customer of new products,
            services or promotions, events or campaigns and to conduct market
            research; to communicate with you, for example to inform you that
            our services have changed or to send you critical alerts and other
            such notices relating to our products and/or services and to contact
            you for customer care related purposes by sending print advertising
            material and by telephone or e-mail, etc.
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>
            Use of Non-Personally Identifiable Data
          </h6>
          <p>
            ‍Anytime you visit this website, we may gather certain
            non-personally identifiable information regarding the means you use
            to access our site. This information may include the type and
            version of your browser, your service provider, your IP address and
            any search engine you may have used to locate the site. We use this
            information to help diagnose problems with our server, administer
            the website, and compile broad statistical data. In addition, we
            gather certain navigational information about where you go on this
            website. This information enables us to determine which areas of the
            websites are most frequently visited and helps us to tailor the
            sites to the needs and interests of our online visitors. Such
            information is gathered by us in the aggregate and will not be
            associated with a specific individual without that individual’s
            consent.
          </p>
          <p>
            Super Estates Ghana recognizes sales in accordance with the
            International Financial Reporting Standards (IFRS) 15 which requires
            sales to be recognized in accordance with contract with customers.
            Super Estates Ghanas consequently enters into contract with
            customers to construct and sell completed houses and service plots
            to customers.
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>
            Refund Policy
          </h6>
          <p>
            Houses and land sold to customers are not returnable. The Company
            therefore does not pay refunds for handed over houses and allocated
            plots of lands. Notwithstanding the above policy, the Company may in
            certain instances assist in finding buyers for customers who want to
            sell their houses and plots of land already purchased from the
            Company. Super Estates Ghana nevertheless pays refunds to customers
            who make payment in advance towards construction of their houses and
            those who make payment over and above the value of the properties
            they acquire. Refunds are also paid to customers who make advance
            deposits towards the purchase of lands.
          </p>
          <h6 style={{ marginTop: "20px", fontWeight: "bold" }}>
            Refund Application Process
          </h6>
          <p>
            A customer who wishes to apply for refund for advance payment may do
            so by officially writing to the Company through their sales
            representative. A customer whose transaction was fronted by a sales
            agent will have to send the refund application to the Company
            through the General Manager, Sales. The reason for refund must be
            stated in all refund applications. Upon receipt of the refund
            application, the sales representative or General Manager, Sales will
            complete a Customer Refund Application Form, attach to the
            application
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Policy;
