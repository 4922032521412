import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../components/Layout/Layout";
import toast from "react-hot-toast";

const Verification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState("");

  const email = new URLSearchParams(location.search).get("email");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://apiestate.superestatesgh.com/api/v1/auth/verify",
        {
          email,
          verificationCode,
        }
      );
      if (res && res.data.success) {
        toast.success(res.data.message);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title="Verification - Super Estates">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <h4 className="title">Verification</h4>
          <div className="mb-3">
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Enter Verification Code"
              required
              autoFocus
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Verify
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Verification;
