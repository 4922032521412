import React from "react";
import Layout from "./../components/Layout/Layout";
import { useSearch } from "../context/search";
import { useNavigate } from "react-router-dom";
import { useCart } from "../context/cart";
import { toast } from "react-hot-toast";

const Search = () => {
  const navigate = useNavigate();
  const [values, setValues] = useSearch();
  const [cart, setCart] = useCart();

  const handleAddToCart = (product) => {
    if (cart.some((item) => item._id === product._id)) {
      toast.error("Item already in cart");
    } else {
      setCart([...cart, product]);
      localStorage.setItem("cart", JSON.stringify([...cart, product]));
      toast.success("Added to cart");
    }
  };

  return (
    <Layout title={"Search results"}>
      <div className="container mt-5">
        <h1 className="text-center mb-5">Search Results</h1>
        <h6 className="text-center mb-4">
          {values?.results.length < 1
            ? "No Products Found"
            : `Found ${values?.results.length}`}
        </h6>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {values?.results.map((p) => (
            <div className="col" key={p._id}>
              <div className="card h-100 d-flex flex-column shadow-sm">
                <img
                  src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo/${p._id}`}
                  style={{ height: "50%", objectFit: "cover" }}
                  alt={p.name}
                />
                <div className="card-body d-flex flex-column justify-content-between">
                  <div>
                    <h5 className="card-title">{p.name}</h5>
                    <p className="card-text mb-3">
                      {p.description.substring(0, 60)}...
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="card-text">GH¢ {p.price}</p>
                    <div>
                      <button
                        className="btn btn-primary me-2"
                        style={{ width: 200 }}
                        onClick={() => navigate(`/product/${p.slug}`)}
                      >
                        Details
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleAddToCart(p)}
                      >
                        Add to list
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Search;
