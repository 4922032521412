import React, { useState } from "react";
import { useSearch } from "../../context/search";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SearchInput = () => {
  const [values, setValues] = useSearch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `https://apiestate.superestatesgh.com/api/v1/product/search/${values.keyword}`
      );
      setValues({ ...values, results: data });
      setIsLoading(false);
      navigate("/search");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <p
        style={{
          fontSize: 35,
          color: "white",
          fontStyle: "normal",
          fontWeight: "bold",
        }}
      >
        Need help finding the most suitable property to fit your needs?
      </p>
      <form role="search" onSubmit={handleSubmit}>
        <div style={{ position: "relative", width: "100%" }}>
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search confidently for your preferred property..."
            aria-label="Search"
            value={values.keyword}
            onChange={(e) => setValues({ ...values, keyword: e.target.value })}
            style={{
              height: "60px",
              textAlign: "center",
              borderRadius: 50,
              paddingRight: "100px", // adjust the padding to fit the button width
            }}
          />
          <button
            type="submit"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              height: "80%",
              backgroundColor: "#0056b3",
              borderRadius: 20,
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
              borderRadius: 25,
              marginRight: 14,
            }}
            disabled={isLoading}
          >
            {isLoading ? "Searching..." : "Search"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchInput;
