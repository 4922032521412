import React from "react";
import Layout from "./../components/Layout/Layout";
import { BiMailSend, BiPhoneCall, BiSupport } from "react-icons/bi";
import logo2 from "../assets/Images/logo2.png";
import { motion } from "framer-motion";

const Contact = () => {
  const imageVariants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.0,
        delay: 0.2,
      },
    },
  };

  return (
    <Layout title={"Contact us - Super Estates"}>
      <div className="row contactus">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <motion.img
            src={logo2}
            alt="contactus"
            style={{ width: "80%" }}
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          />
        </div>
        <div className="col-md-4">
          <h1
            className="p-2 text-white text-center"
            style={{ backgroundColor: "#1665da" }}
          >
            CONTACT US
          </h1>
          <p className="text-justify mt-2">
            Any query and info about products, feel free to call anytime as we
            are available 24X7.
          </p>
          <p className="mt-3">
            <BiMailSend /> : www.help@superestates.com
          </p>
          <p className="mt-3">
            <BiPhoneCall /> : +233 244735274
          </p>
          <p className="mt-3">
            <BiSupport /> : 1800-0000-0000 (toll free)
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
