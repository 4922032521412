import React from "react";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";

const AdminDashboard = () => {
  const [auth] = useAuth();
  return (
    <Layout>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <div className="card p-4 shadow">
              <h3 className="mb-4">Admin Details</h3>
              <div className="mb-3">
                <label htmlFor="admin-name" className="form-label">
                  Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="admin-name"
                  value={auth?.user?.name}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label htmlFor="admin-email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="admin-email"
                  value={auth?.user?.email}
                  readOnly
                />
              </div>
              <div className="mb-3">
                <label htmlFor="admin-contact" className="form-label">
                  Contact:
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="admin-contact"
                  value={auth?.user?.phone}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
