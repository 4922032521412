import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <h1 className="footer-title">All Rights Reserved &copy; SuperEstates</h1>
      <p className="footer-links">
        <Link to="/about" className="footer-link">
          About
        </Link>
        <span className="footer-divider">|</span>
        <Link to="/contact" className="footer-link">
          Contact
        </Link>
        <span className="footer-divider">|</span>
        <Link to="/policy" className="footer-link">
          Privacy Policy
        </Link>
      </p>
    </div>
  );
};

export default Footer;
