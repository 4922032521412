import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout/Layout";
import AdminMenu from "./../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import { Button, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;

const CreateProduct = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState("");
  const [shipping, setShipping] = useState("");
  const [photo, setPhoto] = useState("");
  const [photo1, setPhoto1] = useState("");
  const [photo2, setPhoto2] = useState("");
  const [photo3, setPhoto3] = useState("");
  const [photo4, setPhoto4] = useState("");
  const [photo5, setPhoto5] = useState("");
  const [photo6, setPhoto6] = useState("");
  const [photo7, setPhoto7] = useState("");
  const [photo8, setPhoto8] = useState("");

  //get all category
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        "https://apiestate.superestatesgh.com/api/v1/category/get-category"
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("name", name);
      productData.append("description", description);
      productData.append("price", price);
      productData.append("quantity", quantity);
      productData.append("photo", photo);
      productData.append("photo1", photo1);
      productData.append("photo2", photo2);
      productData.append("photo3", photo3);
      productData.append("photo4", photo4);
      productData.append("photo5", photo5);
      productData.append("photo6", photo6);
      productData.append("photo7", photo7);
      productData.append("photo8", photo8);
      productData.append("category", category);
      const { data } = axios.post(
        "https://apiestate.superestatesgh.com/api/v1/product/create-product",
        productData
      );
      if (data?.success) {
        toast.error(data?.message);
      } else {
        toast.success("Product Created Successfully");
        navigate("/dashboard/admin/products");
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };

  return (
    <Layout title={"Dashboard - Create Product"}>
      <div className="container-fluid m-3 p-3 ">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>Create Product</h1>
            <div className="m-1 w-75">
              <div className="form-group">
                <label htmlFor="categorySelect">Category</label>
                <Select
                  bordered={false}
                  placeholder="Select a category"
                  size="large"
                  showSearch
                  className="form-select"
                  onChange={(value) => {
                    setCategory(value);
                  }}
                  id="categorySelect"
                >
                  {categories?.map((c) => (
                    <Option key={c._id} value={c._id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="form-group">
                <label htmlFor="productName">Product Name</label>
                <Input
                  placeholder="Enter Product Name"
                  id="productName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="productDescription">Product Description</label>
                <TextArea
                  placeholder="Enter Product Description"
                  id="productDescription"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="productPrice">Product Price</label>
                <Input
                  placeholder="Enter Product Price"
                  type="number"
                  id="productPrice"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="productQuantity">Product Quantity</label>
                <Input
                  placeholder="Enter Product Quantity"
                  type="number"
                  id="productQuantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="productPhotos">Product Photos</label>
                <div className="row">
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo ? photo.name : "Upload Main Photo"}
                      <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        onChange={(e) => setPhoto(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo)}
                          alt="product_photo"
                          height={"200px"}
                          width={"250"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo1 ? photo1.name : "Upload Photo 2"}
                      <input
                        type="file"
                        name="photo1"
                        accept="image/*"
                        onChange={(e) => setPhoto1(e.target.files[0])}
                        hidden
                      />
                    </label>

                    {photo1 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo1)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo2 ? photo2.name : "Upload Photo 3"}
                      <input
                        type="file"
                        name="photo2"
                        accept="image/*"
                        onChange={(e) => setPhoto2(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo2 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo2)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo3 ? photo3.name : "Upload Photo 4"}
                      <input
                        type="file"
                        name="photo3"
                        accept="image/*"
                        onChange={(e) => setPhoto3(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo3 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo3)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo4 ? photo4.name : "Upload Photo 5"}
                      <input
                        type="file"
                        name="photo4"
                        accept="image/*"
                        onChange={(e) => setPhoto4(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo4 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo4)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo5 ? photo5.name : "Upload Photo 6"}
                      <input
                        type="file"
                        name="photo5"
                        accept="image/*"
                        onChange={(e) => setPhoto5(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo5 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo5)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo6 ? photo6.name : "Upload Photo 7"}
                      <input
                        type="file"
                        name="photo6"
                        accept="image/*"
                        onChange={(e) => setPhoto6(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo6 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo6)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo7 ? photo7.name : "Upload Photo 8"}
                      <input
                        type="file"
                        name="photo7"
                        accept="image/*"
                        onChange={(e) => setPhoto7(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo7 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo7)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="btn btn-outline-secondary col-md-12">
                      {photo8 ? photo8.name : "Upload Photo 8"}
                      <input
                        type="file"
                        name="photo8"
                        accept="image/*"
                        onChange={(e) => setPhoto8(e.target.files[0])}
                        hidden
                      />
                    </label>
                    {photo8 && (
                      <div className="text-center mt-3">
                        <img
                          src={URL.createObjectURL(photo8)}
                          alt="product_photo"
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleCreate}
                  disabled={
                    !name || !description || !price || !quantity || !category
                  }
                  style={{
                    marginTop: "10px",
                    padding: "10px 20px",
                    borderRadius: 20,
                    width: "200px",
                  }}
                >
                  Create Product
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateProduct;
