import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../styles/ProductDetailsStyles.css";
import { useCart } from "../context/cart";
import { toast } from "react-hot-toast";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/carousel.css";
import SearchInput from "../components/Form/SearchInput";
import { motion } from "framer-motion";
import logo3 from "../assets/Images/logo3.png";

const ProductDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [cart, setCart] = useCart();

  //initalp details
  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);
  //getProduct
  const getProduct = async () => {
    try {
      const { data } = await axios.get(
        `https://apiestate.superestatesgh.com/api/v1/product/get-product/${params.slug}`
      );
      setProduct(data?.product);
      getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };
  //get similar product
  const getSimilarProduct = async (pid, cid) => {
    try {
      const { data } = await axios.get(
        `https://apiestate.superestatesgh.com/api/v1/product/related-product/${pid}/${cid}`
      );
      setRelatedProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  const images = [
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo1/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo2/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo3/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo4/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo5/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo6/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo7/${product._id}`,
    `https://apiestate.superestatesgh.com/api/v1/product/product-photo8/${product._id}`,
  ];
  const imageVariants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.0,
        delay: 0.2,
      },
    },
  };
  return (
    <Layout>
      <div style={{ position: "relative" }}>
        {/* banner image */}
        <motion.img
          src={logo3}
          alt="contactus"
          style={{ width: "100%" }}
          variants={imageVariants}
          initial="hidden"
          animate="visible"
        />
        {/* search component */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <SearchInput />
        </div>
      </div>
      <div className="container product-details">
        <div className="row">
          <div className="col-md-6">
            <div className="product-carousel-container">
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                useKeyboardArrows={true}
                emulateTouch={true}
                dynamicHeight={false}
                showArrows={true}
              >
                {images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt=""
                      className="product-carousel-image"
                    />
                  </div>
                ))}
              </Carousel>
            </div>

            <h1 className="text-center">{product.name}</h1>

            <div className="product-details-info">
              <hr />
              <h6>Description:</h6>
              <p>{product.description}</p>
              <h6>Price:</h6>
              <p>
                {product?.price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "GHs",
                })}
              </p>
              <h6>Category:</h6>
              <p>{product?.category?.name}</p>
              <div style={{ marginLeft: 50 }}>
                <button
                  onClick={() => {
                    // Check if the product is already in the cart
                    const productExists = cart.some(
                      (item) => item._id === product._id
                    );
                    if (productExists) {
                      toast.error("Product already exists in the cart");
                      return;
                    }

                    // If the product is not in the cart, add it
                    setCart([...cart, product]);
                    localStorage.setItem(
                      "cart",
                      JSON.stringify([...cart, product])
                    );
                    toast.success("Item added to cart");
                  }}
                  class="btn btn-secondary ms-1"
                  style={{
                    width: "30%",
                    backgroundColor: "#09143c",
                    marginLeft: 20,
                  }}
                >
                  Add To List
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <h4 className="text-center mb-4 mt-5">Similar Properties</h4>
            <div className="row row-cols-1  g-4">
              {relatedProducts.length < 1 ? (
                <p className="text-center">No similar properties found</p>
              ) : (
                <>
                  <div className="row row-cols-1  g-4">
                    {relatedProducts?.map((p) => (
                      <div className="col mb-4" key={p._id}>
                        <div className="card border-0">
                          <div className="row g-0">
                            <div className="col-md-4">
                              <img
                                src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo/${p._id}`}
                                className="card-img-top rounded"
                                alt={p.name}
                              />
                            </div>
                            <div className="col-md-8">
                              <div className="card-body d-flex flex-column h-100">
                                <h5
                                  className="card-title"
                                  style={{
                                    color: "#1665da",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {p.name}
                                </h5>
                                <p className="card-text">
                                  {p.description.substring(0, 60)}...
                                </p>
                                <div className="mt-auto">
                                  <h5 className=" card-price">
                                    {p.price.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "GHs",
                                    })}
                                  </h5>
                                  <div className="d-flex justify-content-begin">
                                    <button
                                      className="btn btn-primary me-2"
                                      onClick={() =>
                                        navigate(`/product/${p.slug}`)
                                      }
                                    >
                                      More Details
                                    </button>
                                    <button
                                      className="btn btn-dark"
                                      onClick={() => {
                                        // Check if the product is already in the cart
                                        const productExists = cart.some(
                                          (item) => item._id === p._id
                                        );
                                        if (productExists) {
                                          toast.error(
                                            "Product already exists in the cart"
                                          );
                                          return;
                                        }

                                        // If the product is not in the cart, add it
                                        setCart([...cart, p]);
                                        localStorage.setItem(
                                          "cart",
                                          JSON.stringify([...cart, p])
                                        );
                                        toast.success("Item Added to List");
                                      }}
                                    >
                                      Add To List
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductDetails;
