import React from "react";
import Layout from "../../components/Layout/Layout";
import UserMenu from "../../components/Layout/UserMenu";
import { useAuth } from "../../context/auth";

const Dashboard = () => {
  const [auth] = useAuth();

  return (
    <Layout title={"Dashboard - Ecommerce App"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>
          <div className="col-md-9">
            <div className="card p-4">
              <h3 className="mb-4">My Account Information</h3>
              <div className="row mb-3">
                <div className="col-sm-3">
                  <h5>Name:</h5>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted">{auth?.user?.name}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-3">
                  <h5>Email:</h5>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted">{auth?.user?.email}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-3">
                  <h5>Address:</h5>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted">{auth?.user?.address}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
