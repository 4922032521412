import React from "react";
import Layout from "./../components/Layout/Layout";
import logo2 from "../assets/Images/logo2.png";
import { motion } from "framer-motion";

const About = () => {
  const imageVariants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.0,
        delay: 0.2,
      },
    },
  };

  return (
    <Layout title={"About us - Super Estates"}>
      <div className="row  ">
        <div className="col-md-6 ">
          <motion.img
            src={logo2}
            alt="contactus"
            style={{ width: "80%" }}
            variants={imageVariants}
            initial="hidden"
            animate="visible"
          />
        </div>
        <div className="col-md-4">
          <motion.p
            className="text-justify mt-3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <p>
              SuperEstates is a subsidiary of Super Farms Ghana Limited, a
              company that specializes in agriculture and agricultural
              consultancy services. Super Farms is involved in the cultivation
              of a variety of crops, including cashew and mushrooms, as well as
              the raising of livestock such as cattle, pigs, and rabbits.
            </p>
            <p>
              Building on our experience in agriculture, SuperEstates was
              founded to provide high-quality and affordable housing solutions
              to our customers. Our goal is to help people find the perfect home
              that meets their needs and fits their budget.
            </p>
            <p>
              At SuperEstates, we believe that everyone deserves a comfortable
              and secure place to call home. Whether you're a first-time
              homebuyer or looking to upgrade to a larger property, we are here
              to help. We offer a wide range of housing options, including
              apartments, townhouses, and single-family homes, all designed to
              meet the unique needs and preferences of our customers.
            </p>
            <p>
              Our team of experienced real estate professionals is dedicated to
              providing exceptional customer service and helping our clients
              navigate the home-buying process with ease. We are committed to
              transparency and honesty, and we work hard to ensure that our
              clients have all the information they need to make informed
              decisions about their housing choices.
            </p>
            <p>
              Thank you for considering SuperEstates for your housing needs. We
              look forward to working with you and helping you find the perfect
              home!
            </p>
          </motion.p>
        </div>
      </div>
    </Layout>
  );
};

export default About;
