import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout/Layout";
import AdminMenu from "./../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const { Option } = Select;

const UpdateProduct = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState("");

  const [photo1, setPhoto1] = useState("");
  const [photo2, setPhoto2] = useState("");
  const [photo3, setPhoto3] = useState("");
  const [photo4, setPhoto4] = useState("");
  const [photo5, setPhoto5] = useState("");
  const [photo6, setPhoto6] = useState("");
  const [photo7, setPhoto7] = useState("");
  const [photo8, setPhoto8] = useState("");
  const [photo, setPhoto] = useState("");
  const [id, setId] = useState("");

  //get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `https://apiestate.superestatesgh.com/api/v1/product/get-product/${params.slug}`
      );
      setName(data.product.name);
      setId(data.product._id);
      setDescription(data.product.description);
      setPrice(data.product.price);
      setPrice(data.product.price);
      setQuantity(data.product.quantity);

      setCategory(data.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleProduct();
    //eslint-disable-next-line
  }, []);
  //get all category
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        "https://apiestate.superestatesgh.com/api/v1/category/get-category"
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something wwent wrong in getting catgeory");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  //create product function
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("name", name);
      productData.append("description", description);
      productData.append("price", price);
      productData.append("quantity", quantity);
      photo && productData.append("photo", photo);
      photo1 && productData.append("photo1", photo1);
      photo2 && productData.append("photo2", photo2);
      photo3 && productData.append("photo3", photo3);
      photo4 && productData.append("photo4", photo4);
      photo5 && productData.append("photo5", photo5);
      photo6 && productData.append("photo6", photo6);
      photo7 && productData.append("photo7", photo7);
      photo8 && productData.append("photo8", photo8);
      productData.append("category", category);
      const { data } = axios.put(
        `https://apiestate.superestatesgh.com/api/v1/product/update-product/${id}`,
        productData
      );
      if (data?.success) {
        toast.error(data?.message);
      } else {
        toast.success("Product Updated Successfully");
        navigate("/dashboard/admin/products");
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };

  //delete a product
  const handleDelete = async () => {
    try {
      let answer = window.prompt(
        "Type an answer is you want to delete this product"
      );
      if (!answer) return;
      const { data } = await axios.delete(
        `https://apiestate.superestatesgh.com/api/v1/product/delete-product/${id}`
      );
      toast.success("Product Deleted Succesfully");
      navigate("/dashboard/admin/products");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Dashboard - Update Property"}>
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1>Update Property</h1>
            <div className="m-1 w-75">
              <Select
                bordered={false}
                placeholder="Select a category"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setCategory(value);
                }}
                value={category}
              >
                {categories?.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
              <div className="row">
                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo ? photo.name : "Upload Photo"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo1 ? photo1.name : "Upload Photo1"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto1(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo1 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo1)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo1/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo2 ? photo2.name : "Upload Photo2"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto2(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo2 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo2)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo2/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo3 ? photo3.name : "Upload Photo3"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto3(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo3 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo3)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo3/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo4 ? photo4.name : "Upload Photo4"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto4(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo4 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo4)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo4/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo5 ? photo5.name : "Upload Photo5"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto5(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo5 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo5)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo5/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo6 ? photo6.name : "Upload Photo6"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto6(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo6 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo6)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo6/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo7 ? photo7.name : "Upload Photo7"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto7(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo7 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo7)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo7/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-4">
                  <label className="btn btn-outline-secondary col-md-12">
                    {photo8 ? photo5.name : "Upload Photo8"}
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={(e) => setPhoto8(e.target.files[0])}
                      hidden
                    />
                  </label>
                  {photo8 ? (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo8)}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo8/${id}`}
                        alt="product_photo"
                        height={"200px"}
                        width={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-3 mt-3">
                <input
                  type="text"
                  value={name}
                  placeholder="write a name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <textarea
                  type="text"
                  value={description}
                  placeholder="write a description"
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <input
                  type="number"
                  value={price}
                  placeholder="write a Price"
                  className="form-control"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={quantity}
                  placeholder="write a quantity"
                  className="form-control"
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="mb-3">
                  <button
                    className="btn btn-primary"
                    style={{ width: "200px", borderRadius: 20 }}
                    onClick={handleUpdate}
                  >
                    UPDATE PRODUCT
                  </button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="btn btn-danger"
                  style={{ width: "200px", borderRadius: 20 }}
                  onClick={handleDelete}
                >
                  DELETE PRODUCT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UpdateProduct;
