import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Radio } from "antd";
import { Prices } from "../components/Prices";
import { useCart } from "../context/cart";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "./../components/Layout/Layout";
import { AiOutlineReload } from "react-icons/ai";
import "../styles/Homepage.css";
import { motion } from "framer-motion";
import logo3 from "../assets/Images/logo3.png";
import SearchInput from "../components/Form/SearchInput";

const HomePage = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        "https://apiestate.superestatesgh.com/api/v1/category/get-category"
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
    getTotal();
  }, []);
  //get products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `https://apiestate.superestatesgh.com/api/v1/product/product-list/${page}`
      );
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  //getTOtal COunt
  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        "https://apiestate.superestatesgh.com/api/v1/product/product-count"
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);
  //load more
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `https://apiestate.superestatesgh.com/api/v1/product/product-list/${page}`
      );
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // filter by cat
  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };
  useEffect(() => {
    if (!checked.length || !radio.length) getAllProducts();
  }, [checked.length, radio.length]);

  useEffect(() => {
    if (checked.length || radio.length) filterProduct();
  }, [checked, radio]);

  //get filterd product
  const filterProduct = async () => {
    try {
      const { data } = await axios.post(
        "https://apiestate.superestatesgh.com/api/v1/product/product-filters",
        {
          checked,
          radio,
        }
      );
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  const imageVariants = {
    hidden: {
      scale: 0.8,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1.0,
        delay: 0.2,
      },
    },
  };
  return (
    <Layout title={"All Properties - Best offers "}>
      <div style={{ position: "relative" }}>
        {/* banner image */}
        <motion.img
          src={logo3}
          alt="contactus"
          style={{ width: "100%" }}
          variants={imageVariants}
          initial="hidden"
          animate="visible"
          height={500}
        />
        {/* search component */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "80%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <SearchInput />
        </div>
      </div>

      <div className="container-fluid row mt-3 home-page">
        <div className="col-md-3 filters">
          <h4
            className="text-center"
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            FILTER BY CATEGORY
          </h4>
          <div className="d-flex flex-column">
            {categories?.map((c) => (
              <Checkbox
                key={c._id}
                onChange={(e) => handleFilter(e.target.checked, c._id)}
              >
                {c.name}
              </Checkbox>
            ))}
          </div>
          {/* price filter */}
          <h4
            className="text-center mt-4"
            style={{ fontSize: 20, fontWeight: "bold" }}
          >
            FILTER BY PRICE
          </h4>
          <div className="d-flex flex-column">
            <Radio.Group onChange={(e) => setRadio(e.target.value)}>
              {Prices?.map((p) => (
                <div key={p._id}>
                  <Radio value={p.array}>{p.name}</Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
          <div className="d-flex flex-column">
            <button
              style={{ backgroundColor: "#09143c", borderRadius: 50 }}
              className="btn btn-danger"
              onClick={() => window.location.reload()}
            >
              RESET FILTERS
            </button>
          </div>
        </div>
        <div className="col-md-9" style={{ marginTop: 50 }}>
          <h1
            className="text-center"
            style={{ fontStyle: "normal", fontSize: 30 }}
          >
            Available Properties
          </h1>
          <div className="row row-cols-1  g-4">
            {products?.map((p) => (
              <div className="col mb-4" key={p._id}>
                <div className="card border-0">
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img
                        src={`https://apiestate.superestatesgh.com/api/v1/product/product-photo/${p._id}`}
                        className="card-img-top rounded"
                        alt={p.name}
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body d-flex flex-column h-100">
                        <h5 className="card-title">{p.name}</h5>
                        <p className="card-text">
                          {p.description.substring(0, 60)}...
                        </p>
                        <div className="mt-auto">
                          <h5 className=" card-price">
                            {p.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "GHs",
                            })}
                          </h5>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary me-2"
                              onClick={() => navigate(`/product/${p.slug}`)}
                            >
                              More Details
                            </button>
                            <button
                              className="btn btn-dark"
                              onClick={() => {
                                // Check if the product is already in the cart
                                const productExists = cart.some(
                                  (product) => product._id === p._id
                                );
                                if (productExists) {
                                  toast.error(
                                    "Product already exists in the cart"
                                  );
                                  return;
                                }

                                // If the product is not in the cart, add it
                                setCart([...cart, p]);
                                localStorage.setItem(
                                  "cart",
                                  JSON.stringify([...cart, p])
                                );
                                toast.success("Item added to list");
                              }}
                            >
                              Add To List
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="m-2 p-3">
          {products && products.length < total && (
            <button
              className="btn loadmore"
              onClick={(e) => {
                e.preventDefault();
                setPage(page + 1);
              }}
            >
              {loading ? (
                "Loading ..."
              ) : (
                <>
                  {" "}
                  Loadmore <AiOutlineReload />
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
